import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-normal text-gray-500 hover:text-blue-900" smooth to="/report">
                Report
            </HashLink>
            <HashLink className="px-4 font-normal text-gray-500 hover:text-blue-900" smooth to="/finreq">
                Finacial Request Report
            </HashLink>
        </>
    )
}

export default NavLinks;
