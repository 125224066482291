import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import ListInvoice from './pages/ListInvoices.js';
import Invoice from './pages/Invoice';
import Report from './pages/Report';
import FinancialRequest from './pages/FinancialRequest.js';
import FinreqInvoice from './pages/FinreqInvoice.js';
import CustomerProfile from './pages/CustomerProfile.js';
import Camera from './pages/Camera.js';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("SME UPLOAD FILE");

  // /INV/2024/00034

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/invoice/:id" element={<ListInvoice />} /> 
            <Route path="/detail-invoices/:inv" element={<Invoice />} /> 
            {/* INV-081224-225434-0060381 */}
            <Route path="/finreq-invoices/:inv" element={<FinreqInvoice />} /> 
            <Route path="/report" element={<Report />} /> 
            <Route path="/finreq" element={<FinancialRequest />} /> 
            <Route path="/cust-profile/:id" element={<CustomerProfile />} /> 
            <Route path="/bank-ina" element={<Camera />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
