import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';


const invoiceLocalUrl = 'localhost:3000'



const CustomerProfile = (props) => {
    const { inv, year, id } = useParams();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [invoices, setInvoices] = useState([])
    const [errors, setErrors] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/report');
    };

    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const value = e.target.value
        const checked = e.target.checked
        errors.products = []
        if (checked) {
            setInvoices([
                ...invoices, value
            ])
        } else {
            setInvoices(invoices.filter((e) => (e !== value)))
        }

    }
    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const handleDownload = () => {
        // Implement download logic here
        console.log("Download button clicked");
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log("File uploaded:", file);
        // Implement file upload logic here
    };

    const arrayOfObjects = [
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
    ];

    function sendEmail(e) {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)
        fData.append('products', invoices)

        // emailjs.sendForm('service_7uy4ojg', 'template_et9wvdg', e.target, 'user_uE0bSPGbhRTmAF3I2fd3s')
        //   .then((result) => {
        //       console.log(result.text);
        //       Notiflix.Report.success(
        //         'Success',
        //         '"Thanks for sending a message, we\'ll be in touch soon."',
        //         'Okay',
        //         );
        //   }, (error) => {
        //       console.log(error.text);
        //       Notiflix.Report.failure(
        //         'An error occured',
        //         'Please try sending the message again.',
        //         'Okay',
        //         );
        //   });

        axios({
            method: "post",
            url: process.env.REACT_APP_DEMO_REQUEST_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }

    const dataList = [
        {
            rowNum: 2,
            warungName: 7,

            invoiceDate: "2024-08-08",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-08",
            invoiceNo: "INV/2024/00034",
            paymentStatus: "Late",
            untaxedAmount: 305,
            taxAmount: null,
            totalCurrency: 305,
            total: 305,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060381",
        },
        {
            rowNum: 3,
            warungName: 2,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00033",
            paymentStatus: "On-time",
            untaxedAmount: 90,
            taxAmount: null,
            totalCurrency: 90,
            total: 90,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060382",


        },
        {
            rowNum: 4,
            warungName: 2,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00032",
            paymentStatus: "On-time",
            untaxedAmount: 18,
            taxAmount: null,
            totalCurrency: 18,
            total: 18,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060383",


        },
        {
            rowNum: 5,
            warungName: 5,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00031",
            paymentStatus: "On-time",
            untaxedAmount: 280,
            taxAmount: null,
            totalCurrency: 280,
            total: 280,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060384",

        },
        {
            rowNum: 6,
            warungName: 4,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00030",
            paymentStatus: "REJECT",
            untaxedAmount: 100520,
            taxAmount: null,
            totalCurrency: 100520,
            total: 100520,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060385",
        },
        {
            rowNum: 7,
            warungName: 7,

            invoiceDate: "2024-08-05",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-05",
            invoiceNo: "INV/2024/00029",
            paymentStatus: "On-time",
            untaxedAmount: 16,
            taxAmount: null,
            totalCurrency: 16,
            total: 16,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060386",

        },
        {
            rowNum: 8,
            warungName: 6,

            invoiceDate: "2024-07-03",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-07-03",
            invoiceNo: "INV/2024/00028",
            paymentStatus: "On-time",
            untaxedAmount: 38,
            taxAmount: null,
            totalCurrency: 38,
            total: 38,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060387",

        },
        {
            rowNum: 9,
            warungName: 7,

            invoiceDate: "2024-04-25",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-25",
            invoiceNo: "INV/2024/00027",
            paymentStatus: "On-time",
            untaxedAmount: 955,
            taxAmount: null,
            totalCurrency: 955,
            total: 955,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060387",

        },
        {
            rowNum: 10,
            warungName: 7,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00026",
            paymentStatus: "Late",
            untaxedAmount: 955,
            taxAmount: null,
            totalCurrency: 955,
            total: 955,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060388",

        },
        {
            rowNum: 11,
            warungName: 7,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00025",
            paymentStatus: "Late",
            untaxedAmount: 1295,
            taxAmount: null,
            totalCurrency: 1295,
            total: 1295,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060389",

        },
        {
            rowNum: 12,
            warungName: 3,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00024",
            paymentStatus: "Late",
            untaxedAmount: 137,
            taxAmount: null,
            totalCurrency: 137,
            total: 137,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060390",

        },
        {
            rowNum: 13,
            warungName: 4,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00023",
            paymentStatus: "Late",
            untaxedAmount: 1295,
            taxAmount: null,
            totalCurrency: 1295,
            total: 1295,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060391",

        },
        {
            rowNum: 14,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00022",
            paymentStatus: "Late",
            untaxedAmount: 12875,
            taxAmount: null,
            totalCurrency: 12875,
            total: 12875,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060391",

        },
        {
            rowNum: 15,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00021",
            paymentStatus: "Late",
            untaxedAmount: 7005,
            taxAmount: null,
            totalCurrency: 7005,
            total: 7005,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060392",

        },
        {
            rowNum: 16,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00020",
            paymentStatus: "Late",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060393",

        },
        {
            rowNum: 17,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00019",
            paymentStatus: "Late",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060394",

        },
        {
            rowNum: 18,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00018",
            paymentStatus: "Late",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060395",

        },
        {
            rowNum: 19,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00017",
            paymentStatus: "On-time",
            untaxedAmount: 2865,
            taxAmount: null,
            totalCurrency: 2865,
            total: 2865,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060396",

        },
        {
            rowNum: 20,
            warungName: 3,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00016",
            paymentStatus: "Late",
            untaxedAmount: 165,
            taxAmount: null,
            totalCurrency: 165,
            total: 165,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060397",

        },
        {
            rowNum: 21,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00015",
            paymentStatus: "Late",
            untaxedAmount: 276,
            taxAmount: null,
            totalCurrency: 276,
            total: 276,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060398",

        },
        {
            rowNum: 22,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00014",
            paymentStatus: "Late",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060399",

        },
        {
            rowNum: 23,
            warungName: 3,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00013",
            paymentStatus: "Late",
            untaxedAmount: 1345,
            taxAmount: null,
            totalCurrency: 1345,
            total: 1345,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060400",

        },
        {
            rowNum: 24,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00012",
            paymentStatus: "Late",
            untaxedAmount: 165,
            taxAmount: null,
            totalCurrency: 165,
            total: 165,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060401",

        },
        {
            rowNum: 25,
            warungName: 7,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00011",
            paymentStatus: "Late",
            untaxedAmount: 15,
            taxAmount: null,
            totalCurrency: 15,
            total: 15,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060401",

        },
        {
            rowNum: 26,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00010",
            paymentStatus: "Late",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060402",

        },
        {
            rowNum: 27,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00009",
            paymentStatus: "Late",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060403",

        },
        {
            rowNum: 28,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00008",
            paymentStatus: "Late",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            reference_number: "INV-081224-225434-0060404",

        },
    ];

    const replaceSlashesWithHyphens = (id) => {
        return id.replace(/\//g, '-');
    };

    const handleNavigateInvoice = (id) => {
        console.log(id);

        const refNo = replaceSlashesWithHyphens(id) || '412312412341321';


        navigate(`/finreq-invoices/${refNo}`, { state: id });
    };

    const handleNavigateCustProfile = (id) => {
        console.log(id);
        const refNo = replaceSlashesWithHyphens(id) || '412312412341321';
        navigate(`/cust-profile/${id || 1231412123}`, { state: id });
    };

    const InvoiceTable = ({ data }) => {
        console.log(data);

        // {
        //     rowNum: 28,
        // warungName : 81,
        //     invoiceDate: "2024-04-23",
        //     isPartnerActive: true,
        //     partnerName: "Ecomart",
        // warungName : "11",
        //     partnerEmail: "087908790879@bakuul.com",
        //     partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
        //     dueDate: "2024-04-23",
        //     invoiceNo: "INV/2024/00008",
        //     paymentStatus: "Late",
        //     untaxedAmount: 665,
        //     taxAmount: null,
        //     totalCurrency: 665,
        //     total: 665,
        //     currency: "PHP",
        //     reference_number: "INV-081224-225434-0060404",

        // },

        return (
            <div className="w-full">
                <div className="overflow-y-auto">
                    <h1 className="my-5 mb-5 font-bold text-center px-3 lg:text-left text-gray-900 uppercase text-3xl">
                        SME TRANSACTION REPORT
                    </h1>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Reference No
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    User Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice No
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice Amount
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Outstanding Amount
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Due Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Payment Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data.map((invoice) => (
                                <tr key={invoice.invoiceNo}>
                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <a
                                        href={`${invoiceLocalUrl}/detail-invoices/${invoice.invoiceNo}`}
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:text-blue-700 underline"
                                    >
                                        {invoice.invoiceNo}
                                    </a>
                                </td> */}
                                    <td className="px-6 py-4 whitespace-normal text-sm text-gray-900 break-words">
                                        <a
                                            onClick={() => handleNavigateInvoice(invoice.rowNum)}
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:text-blue-700 underline"
                                        >
                                            {invoice.refNo || '412312412341321'}
                                        </a>
                                    </td>
                                    <div className="flex items-center">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 break-words">
                                            {'Wholesaler ' + invoice.warungName}
                                        </td>
                                    </div>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.invoiceNo}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.total} {invoice.currency}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.untaxedAmount} {invoice.currency}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.invoiceDate}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.dueDate}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        <span
                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                                ${invoice.paymentStatus === "PAID" ? "bg-green-100 text-green-800" :
                                                    invoice.paymentStatus === "On-time" ? "bg-blue-100 text-blue-800" :
                                                        invoice.paymentStatus === "Late" ? "bg-orange-100 text-orange-800"
                                                            : "bg-red-100 text-red-800"
                                                }`}
                                        >
                                            {invoice.paymentStatus}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div
                id="demo"
                className="flex justify-center items-center mt-8 w-full bg-green-50 py-12"
            >
                <div className="container mx-auto my-8 px-4" data-aos="zoom-in">
                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div>
                            {/* Invoice/Bill Details Section */}
                            <div className="p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">Customer Profile</h2>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-md text-sm text-md font-medium leading-5 mb-2">Invoice Number:</p>
                                        <span className="block text-sm text-md font-medium leading-5">{inv}/{year}/{id}</span>
                                        <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Issue Date:</p>
                                        <span className="block text-sm text-md font-medium leading-5">09/01/2021 04:02 PM</span>
                                        <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Due Date:</p>
                                        <span className="block text-sm text-md font-medium leading-5">09/16/2021</span>
                                    </div>
                                    <div>
                                        <p className="text-md text-sm text-md font-medium leading-5 mb-2">Customer:</p>
                                        <span className="block text-sm text-md font-medium leading-5">Jennifer Dizon</span>
                                        <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Change Amount:</p>
                                        <span className="block text-sm text-md font-medium leading-5">PHP 2.00</span>
                                        <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Collection Account:</p>
                                        <span className="block text-sm text-md font-medium leading-5">XXXXXXXX4566</span>
                                    </div>
                                </div>

                            </div>


                            {/* Summary Section */}
                            <div className="p-6 bg-gray-50 flex justify-end ">
                            </div>
                            {<InvoiceTable data={dataList} />}
                        </div>
                    </div>
                    <div class="flex justify-between mt-4">
                        <button onClick={() => handleBack()} class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CustomerProfile;