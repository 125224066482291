import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
// import emailjs from 'emailjs-com';
import Notiflix from "notiflix";
import moment from "moment";


import upload_btn from "../images/upload.png";
import download_btn from "../images/download.png";
import back_btn from "../images/back.png";

// const invoiceLocalUrl = process.env.REACT_APP_SME_INVOICE_LOCAL;
const invoiceLocalUrl = 'http://localhost:3000'


const Report = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [demoProducts, setDemoProducts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [verifyFileDataValid, setVerifyFileDataValid] = useState(null);
  const [verifyFileDataInvalid, setVerifyFileDataInvalid] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/report');
  };

  const onCloseModal = () => {
    setResponseData([])
    setFile(null)
    setFileName(null)
    setIsModalOpen(false);
  }

  const handleUpload = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchInvoiceDetail();
  }, []);

  // const handleChange = (e) => {
  //     const value = e.target.value
  //     const checked = e.target.checked
  //     errors.products = []
  //     if (checked) {
  //         setDemoProducts([
  //             ...demoProducts, value
  //         ])
  //     } else {
  //         setDemoProducts(demoProducts.filter((e) => (e !== value)))
  //     }

  // }
  // const clearErrors = () => {
  //     setErrors([])
  // }

  // const clearInput = () => {
  //     setFirstName('')
  //     setLastName('')
  //     setEmail('')
  //     setPhone('')
  //     setMessage('')
  // }

  const fetchInvoiceDetail = async () => {
    console.log("123"); // Check if the function is being called

    try {
      const response = await axios.get(
        "https://beams-fe-dev.bayargw.com/beamsinvoice/invoice-file/list",
        {
          headers: {
            "Content-Type": "application/json",
            // Add any other headers if required
          },
        }
      );

      console.log("Full response:", response); // Log the entire response
      console.log("Data:", response.data); // Log the data specifically
      setInvoiceData(response.data);
    } catch (error) {
      console.log("Error fetching invoice details:", error); // Log any error that occurs
    }
  };

  const postFileExcel = async () => {
    console.log("123"); // Check if the function is being called

    if (!file) {
      setError("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Assuming the backend expects a 'file' key

    try {
      const response = await axios.post(
        "https://beams-fe-dev.bayargw.com/beamsinvoice/invoice-file/submit",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers if required
          },
        }
      );

      console.log("Full response:", response); // Log the entire response
      console.log("Data:", response.data); // Log the data specifically

      if (response.data) {
        setIsModalOpen(false);
        navigate('/report');
      } else {
        setError("No data available.");
      }
    } catch (err) {
      console.log("Error fetching invoice details:", error);
    }
  };

  const handleDownload = () => {
    // Implement download logic here
    console.log("Download button clicked");
    fetchInvoiceDetail();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(event.target.files[0].name)
    console.log(event.target.files[0].name, 'name');

    console.log("File uploaded:", selectedFile);

  };

  function sendEmail(e) {
    e.preventDefault();
  }


  const handleNavigateInvoice = (id) => {
    // href={`${invoiceLocalUrl}/invoice/${invoice.id}`}

    console.log(id);

    navigate(`/invoice/${id}`, { state: id });
  };

  const handleNavigateCustProfile = (id) => {
    console.log(id);

    navigate(`/cust-profile/${id}`, { state: id });
  };

  const InvoiceTable = ({ data }) => {
    console.log(data);

    // [
    //     {
    //         "id": "eccce762-a105-420e-bad9-4bc70afacc91",
    //         "filename": "Journal Entry (account.move) Invoice.xlsx",
    //         "uploadDate": "2024-08-10 21:13:56.949"
    //     }
    // ]
    const uploadDateFix = moment(data.uploadDate).format("YYYY-MM-DD HH:mm");
    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Report No
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                File Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Upload Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Detail
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((invoice) => (
              <tr key={invoice.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {invoice.id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {invoice.filename}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {uploadDateFix}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <a
                    // href={`${invoiceLocalUrl}/invoice/${invoice.id}`}
                    onClick={() => handleNavigateInvoice(invoice.id)}
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700 underline"
                  >
                    {invoice.filename}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const TransactionList = ({ data }) => {
    console.log(data.validData);
    console.log(data.invalidData);

    // Render loading message or transactions
    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p className="text-red-600">{error}</p>;
    }

    if (!data || data.length === 0) {
      return <p>No transactions available.</p>;
    }

    // rowNum: 2,
    // dueDate: null,
    // isPartnerActive: null,
    // partnerName: null,
    // partnerEmail: 'LICNISSMMSSM027',
    // partnerAddress: 'Development',
    // txnDate: null,
    // invoiceNo: null,
    // paymentStatus: null,
    // untaxedAmount: null,
    // taxAmount: null,
    // totalCurrency: null,
    // total: null,
    // currency: null

    //     rowNum: 2,
    //   dueDate: '2024-08-08',
    //   isPartnerActive: true,
    //   partnerName: 'Rizky Mart',
    //   partnerEmail: '088708870887@bakuul.com',
    //   partnerAddress: 'St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia',
    //   txnDate: '2024-08-08',
    //   invoiceNo: 'INV/2024/00034',
    //   paymentStatus: 'NOT_PAID',
    //   untaxedAmount: 305,
    //   taxAmount: null,
    //   totalCurrency: 305,
    //   total: 305,
    //   currency: 'PHP'

    return (
      <div className="container mx-auto p-4 overflow-x-auto overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">List Transaction</h1>
        <div className="overflow-x-auto overflow-y-auto h-40">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Invoice Number</th>
                <th className="py-3 px-6 text-left">Customer</th>
                <th className="py-3 px-6 text-right">Invoice Amount</th>
                <th className="py-3 px-6 text-right">Paid Amount</th>
                <th className="py-3 px-6 text-left">Collection Account</th>
                <th className="py-3 px-6 text-left">Invoice Date</th>
                <th className="py-3 px-6 text-left">Due Date</th>
                <th className="py-3 px-6 text-center">Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {data.validData.map((transaction, index) => (
                <tr
                  key={index}
                  className={
                    index % 2 === 0
                      ? "bg-gray-50 hover:bg-gray-100"
                      : "bg-white hover:bg-gray-100"
                  }
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {transaction.invoiceNo}
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          className="w-6 h-6 rounded-full"
                          src={`https://ui-avatars.com/api/?name=${transaction.partnerName}&color=7F9CF5&background=EBF4FF`}
                          alt={transaction.partnerName}
                        />
                      </div>
                      <span>{transaction.partnerName}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left">{transaction.total}</td>
                  <td className="py-3 px-6 text-right">-</td>
                  <td className="py-3 px-6 text-right">
                    {transaction.partnerEmail}
                  </td>
                  <td className="py-3 px-6 text-left">{transaction.txnDate}</td>
                  <td className="py-3 px-6 text-left">{transaction.dueDate}</td>
                  <td className="py-3 px-6 text-center">
                    <span
                      className={`${transaction.paymentStatus === "Complete"
                        ? "bg-green-200 text-green-600"
                        : "bg-red-200 text-red-600"
                        } py-1 px-3 rounded-full text-xs`}
                    >
                      {transaction.paymentStatus}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {data.invalidData.length > 0 ? (
          <div className="overflow-x-auto overflow-y-auto h-40">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Invoice Number</th>
                  <th className="py-3 px-6 text-left">Customer</th>
                  <th className="py-3 px-6 text-right">Invoice Amount</th>
                  <th className="py-3 px-6 text-right">Paid Amount</th>
                  <th className="py-3 px-6 text-left">Invoice Date</th>
                  <th className="py-3 px-6 text-left">Due Date</th>
                  <th className="py-3 px-6 text-center">Status</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {data.invalidData.map((transaction, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "bg-gray-50 hover:bg-gray-100"
                        : "bg-white hover:bg-gray-100"
                    }
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      {transaction.invoiceNo}
                    </td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex items-center">
                        <div className="mr-2">
                          <img
                            className="w-6 h-6 rounded-full"
                            src={`https://ui-avatars.com/api/?name=${transaction.customer}&color=7F9CF5&background=EBF4FF`}
                            alt={transaction.customer}
                          />
                        </div>
                        <span>{transaction.customer}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-right">
                      {transaction.partnerName}
                    </td>
                    <td className="py-3 px-6 text-left">{transaction.total}</td>
                    <td className="py-3 px-6 text-left">
                      {transaction.txnDate}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {transaction.dueDate}
                    </td>
                    <td className="py-3 px-6 text-center">
                      <span
                        className={`${transaction.paymentStatus === "Complete"
                          ? "bg-green-200 text-green-600"
                          : "bg-red-200 text-red-600"
                          } py-1 px-3 rounded-full text-xs`}
                      >
                        {transaction.paymentStatus}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {/* <div className="mt-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
                    <p className="font-bold">Note:</p>
                    <p>✓ Complete and correctly formatted data entries will be saved and displayed in the transaction report after pressing the Save button.</p>
                    <p>✗ Incomplete data entries, please update the data and re-upload the file after pressing the Save button.</p>
                </div> */}
      </div>
    );
  };

  const verifyFile = async () => {
    setLoading(true); // Set loading to true when starting API call
    setError(""); // Reset error message

    if (!file) {
      setError("No file selected.");
      setLoading(false); // Set loading to false if no file
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://beams-fe-dev.bayargw.com/beamsinvoice/invoice-file/verify",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      setResponseData(response.data); // Update state with API response

      if (response.data) {
        setResponseData(response.data); // Update state with API response
      } else {
        setError("No data available.");
        setResponseData(null); // Ensure responseData is null if no data
      }
    } catch (err) {
      console.log(err);

      setError("Error fetching invoice details.");
      setResponseData(null); // Ensure responseData is null in case of error
    } finally {
      setLoading(false); // Set loading to false when API call completes
    }
  };

  const handleBackToBo = () => {
    window.location.href = 'https://beams-fe-dev.bayargw.com/bo-dash/dashboard'
  }

  const UploadModal = ({ isOpen, onClose }) => {

    if (!isOpen) return null;

    console.log(responseData);

    return (
      <div
        className="fixed inset-0 flex items-center justify-center z-50 overflow-x-auto overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        <div className="bg-white w-full max-w-6xl p-8 rounded-lg shadow-lg z-10">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
          <div className="container mx-auto mt-10">
            <TransactionList data={responseData || []} />
          </div>
          <div
            type="input"
            className="border-2 border-dashed mt-5 border-gray-300 rounded-lg p-8 text-center"
          >
            <div className="mb-4">
              <svg
                className="mx-auto h-12 w-12 text-gray-400" /* SVG path here */
              />
            </div>
            <p className="text-lg mb-2">Drag and drop your file here</p>
            <input
              type="file"
              className="text-sm text-stone-500
                            file:mr-5 file:py-1 file:px-3 file:border-[1px]
                            file:text-xs file:font-medium
                            file:bg-stone-50 file:text-stone-700
                            hover:file:cursor-pointer hover:file:bg-blue-50
                            hover:file:text-blue-700"
              onChange={handleFileChange}
            />
            {fileName !== null ? <p className="text-xs text-gray-400 mt-2">{fileName}</p> : ''}
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => onCloseModal()}
              className="px-4 py-2 text-gray-800 hover:text-gray-800 mr-2"
            >
              Cancel
            </button>
            {responseData !== null ? (
              <button
                onClick={postFileExcel}
                className="px-4 py-2 bg-green-500 mx-2 text-white rounded hover:bg-green-600"
              >
                Save
              </button>
            ) : null}

            <button
              onClick={verifyFile}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Verify File
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="demo"
        className="flex justify-center items-center mt-8 w-full bg-green-50 py-12"
      >
        <div className="container mx-auto my-8 px-4" data-aos="zoom-in">
          <form onSubmit={sendEmail} id="demoProductForm">
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <h1 className="font-bold text-center lg:text-left text-green-900 uppercase text-4xl">
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700 focus:outline-none mx-3"
                  onClick={() => handleBackToBo()}
                  style={{ background: "none", border: "none", padding: 0 }}
                >
                  <img
                    src={back_btn}
                    alt="Download"
                    style={{ width: "22px", height: "22px" }}
                  />
                </button>
                SME TRANSACTION REPORT
              </h1>

              {/* Search bar and buttons aligned to the right */}
              <div className="flex justify-end items-center mt-6 mb-4">
                <div className="relative">
                  <input
                    type="text"
                    className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                    placeholder="Search anything..."
                  />
                  <div className="absolute top-4 right-3">
                    <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
                  </div>
                </div>

                {/* Download button */}
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700 focus:outline-none mx-3"
                  onClick={handleDownload}
                  style={{ background: "none", border: "none", padding: 0 }}
                >
                  <img
                    src={download_btn}
                    alt="Download"
                    style={{ width: "22px", height: "22px" }}
                  />
                </button>

                {/* Upload button */}
                <button
                  onClick={() => setIsModalOpen(true)}
                  style={{ background: "none", border: "none", padding: 0 }}
                >
                  <img
                    src={upload_btn}
                    alt="Upload"
                    style={{ width: "22px", height: "22px" }}
                  />
                </button>
              </div>
              {<InvoiceTable data={invoiceData} />}
            </div>
          </form>
          <div class="flex justify-between mt-4">
            <button onClick={() => handleBack()} class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Back
            </button>
          </div>
        </div>
        <UploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>

      <Footer />
    </>
  );
};

export default Report;
