import React, { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';
import Camera from '../components/Camera';
import axios from 'axios';
import platform from 'platform';
import DeviceDetector from 'device-detector-js';

function App() {
  const [deviceId, setDeviceId] = useState('');
  const [browserInfo, setBrowserInfo] = useState('');
  const [osInfo, setOsInfo] = useState({ os: '', osVersion: '', model: '', manufacture: '' });

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;

      // Check if the device ID is already stored in the cookie
      let storedDeviceId = Cookies.get('device_id');
      if (!storedDeviceId) {
        // If not, store the new visitor ID in the cookie
        Cookies.set('device_id', visitorId, { expires: 365 });
        storedDeviceId = visitorId;
      }
      setDeviceId(storedDeviceId);
    };

    const getBrowserInfo = () => {
      const userAgent = navigator.userAgent;
      let browserName = 'Unknown';
      console.log(userAgent);

      if (userAgent.indexOf('Firefox') > -1) {
        browserName = 'Mozilla Firefox';
      } else if (userAgent.indexOf('SamsungBrowser') > -1) {
        browserName = 'Samsung Internet';
      } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browserName = 'Opera';
      } else if (userAgent.indexOf('Trident') > -1) {
        browserName = 'Microsoft Internet Explorer';
      } else if (userAgent.indexOf('Edg') > -1) {
        browserName = 'Microsoft Edge';
      } else if (userAgent.indexOf('Chrome') > -1) {
        browserName = 'Google Chrome';
      } else if (userAgent.indexOf('Safari') > -1) {
        browserName = 'Apple Safari';
      }
      setBrowserInfo(browserName);
    };

    const getDeviceBrand = (userAgent) => {
      const brands = [
        { name: 'Apple', keywords: ['iPhone', 'iPad'] },
        { name: 'Samsung', keywords: ['Samsung'] },
        { name: 'Huawei', keywords: ['Huawei'] },
        { name: 'OnePlus', keywords: ['OnePlus'] },
        { name: 'Lenovo', keywords: ['Lenovo'] },
        { name: 'Dell', keywords: ['Dell'] },
        { name: 'HP', keywords: ['HP'] },
        { name: 'Acer', keywords: ['Acer'] },
        { name: 'Asus', keywords: ['Asus'] },
        { name: 'Microsoft', keywords: ['Microsoft'] },
        { name: 'Toshiba', keywords: ['Toshiba'] },
        { name: 'Sony', keywords: ['Sony'] },
        { name: 'LG', keywords: ['LG'] },
      ];

      for (const brand of brands) {
        for (const keyword of brand.keywords) {
          if (userAgent.indexOf(keyword) > -1) {
            return brand.name;
          }
        }
      }
      return 'Unknown';
    };

    const getOsInfo = () => {
      console.log(platform.os);
      const os = platform.os.family || 'Unknown';
      const osVersion = platform.os.version || 'Unknown';
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      let model = device.device.model || 'Unknown';
      let manufacture = device.device.brand || 'Unknown';

      console.log(device.type);
      console.log(navigator.userAgent);

      // Additional checks for common brands in user agent string
      if (manufacture === 'Unknown') {
        manufacture = getDeviceBrand(navigator.userAgent);
      }

      // If the device type is desktop or laptop, set model and manufacture accordingly
      if (device.device.type === 'desktop' || device.device.type === 'laptop') {
        model = 'Laptop/Desktop';
        if (manufacture === 'Unknown') {
          manufacture = 'Generic';
        }
      }

      setOsInfo({ os, osVersion, model, manufacture });
      console.log('Platform Info:', platform); // Log the platform object to see available information
      console.log('Device Info:', device); // Log the device object to see available information
    };

    loadFingerprint();
    getBrowserInfo();
    getOsInfo();  
  }, []);

  const postFileExcel = async () => {
    console.log('123'); // Check if the function is being called
    // Body {"corpId" : [corpId from login process response], "deviceId":"Microsoft edge - 89328493284"}
    const Body = {
      corpId: '2c9180838ba1aed7018ba30d6cf600aa',
      deviceId: `${browserInfo} - ${deviceId}`,
      os: osInfo.os,
      osVersion: osInfo.osVersion,
      model: osInfo.model,
      manufacture: osInfo.manufacture
    };

    try {
      const response = await axios.post(
        'http://localhost/bob/account/bindingAccount',
        Body,
        {
          headers: {
            'Content-Type': 'application/json' // "Content-Type": "multipart/form-data", // Add any other headers if required
          }
        }
      );
      console.log('Full response:', response); // Log the entire response
      console.log('Data:', response.data); // Log the data specifically
      // if (response.data) {
      // navigate('/report');
      // } else {
      // setError("No data available.");
      // }
    } catch (err) {
      console.log('Error fetching invoice details:', err);
    }
  };

  return (
    <div>
      <Camera />
      <div>App</div>
      <div>Device ID: {deviceId}</div>
      <div>Browser: {browserInfo}</div>
      <div>OS: {osInfo.os}</div>
      <div>OS Version: {osInfo.osVersion}</div>
      <div>Model: {osInfo.model}</div>
      <div>Manufacture: {osInfo.manufacture}</div>
      <button onClick={postFileExcel}>INA BANK</button>
    </div>
  );
}

export default App;