import React, { useState, useEffect } from "react";
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Notiflix from 'notiflix';

const FinreqInvoice = (props) => {
    const { inv, year, id } = useParams();
    const [invoices, setInvoices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [invoiceData, setInvoiceData] = useState(null); // Initialize as null
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();
    const location = useLocation();
    const invoiceId = location.state;
    

    console.log(invoiceId);

    useEffect(() => {
        fetchInvoiceDetail();
    }, []);

    const fetchInvoiceDetail = async () => {
        console.log("123"); // Check if the function is being called

        try {
            const response = await axios.get(
                `https://beams-fe-dev.bayargw.com/beamsinvoice/financial-report/detail/${invoiceId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        // Add any other headers if required
                    },
                }
            );

            console.log("Full response:", response); // Log the entire response
            console.log("Data:", response.data); // Log the data specifically
            setInvoiceData(response.data.data);
        } catch (error) {
            console.log("Error fetching invoice details:", error); // Log any error that occurs
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };


    const updateStatusInvoices = async () => {
        console.log("123"); // Check if the function is being called

        try {
            const response = await axios.post(
                `https://beams-fe-dev.bayargw.com/beamsinvoice/financial-report/update-status`,
                {
                    id: invoiceId,
                    status: modalAction === 'approve' ? 3 :
                        modalAction === 'paid' ? 4 :
                            modalAction === 'reject' ? 2 : 3
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        // Add any other headers if required
                    },
                }
            );

            console.log("Full response:", response); // Log the entire response
            console.log("Data:", response.data); // Log the data specifically
            setInvoiceData(response.data.data);
        } catch (error) {
            console.log("Error fetching invoice details:", error); // Log any error that occurs
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    const handleBack = () => {
        navigate('/finreq');
    };

    const handleActionClick = (action) => {
        setModalAction(action);
        setShowModal(true);
    };

    const handleConfirm = () => {
        // Perform action based on modalAction
        console.log(`Confirmed ${modalAction}`);
        updateStatusInvoices()
        setShowModal(false);
        navigate('/finreq');
    };

    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const DetailInvoice = ({ data }) => {
        console.log(data);

        return (
            <div className="container mx-auto my-8 px-4">
                <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                    {/* Invoice/Bill Details Section */}
                    <div className="p-6">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">Invoice/Bill Details</h2>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-md text-sm text-md font-medium leading-5 mb-2">Reference Number:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.refNo || '1231241214'}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Number:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.invoiceNo}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(data.invoiceAmount).toLocaleString('id-ID')} {data.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Paid Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(data.invoiceAmount).toLocaleString('id-ID')} {data.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Date:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.invoiceDateFmt}</span>
                            </div>
                            <div>
                                <p className="text-md text-sm text-md font-medium leading-5 mb-2">User:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.userName || 'User Name'}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Customer:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.customerName}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Charge Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(data.outStandingAmount).toLocaleString('id-ID')} {data.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Collection Account:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.collectionAccount}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Due Date:</p>
                                <span className="block text-sm text-md font-medium leading-5">{data.dueDateFmt}</span>
                            </div>
                        </div>
                    </div>

                    {/* Items Section */}
                    <div className="p-6">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-sm text-md font-medium leading-5">Item</th>
                                    <th className="px-6 py-3 text-left text-sm text-md font-medium leading-5">Item Description</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Price</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Discount</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Qty</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {data.financialReportDetails.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-md font-medium leading-5 h-auto">{item.itemCode}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-md font-medium leading-5 h-auto">{item.itemName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.qty}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.discount}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.price.toFixed(2)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Summary Section */}
                    <div className="p-6 bg-white flex justify-end ">
                        <div className="w-full max-w-xs">
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Amount Incl Tax</p>
                                <p>{parseInt(data.invoiceAmount).toLocaleString('id-ID')} {data.currency}</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Tax Amount</p>
                                <p>{parseInt(data.tax || 0).toLocaleString('id-ID')} {data.currency}</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Paid Amount</p>
                                <p>{parseInt(data.invoiceAmount).toLocaleString('id-ID')} {data.currency}</p>
                            </div>
                            <div className="flex justify-between font-semibold text-md text-md leading-5 mt-2">
                                <p>Invoice Amount</p>
                                <p>{parseInt(data.invoiceAmount).toLocaleString('id-ID')} {data.currency}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between mt-4">
                        <button onClick={() => handleBack()} className="hover:bg-gray-500 bg-gray-800 text-white py-2 px-6 rounded-lg">
                            Back
                        </button>
                        <div className="flex space-x-4">
                            {
                                invoiceData.statusRequest === 'Waiting Approval' ?
                                    <div className="flex space-x-4">
                                        <button onClick={() => handleActionClick('reject')} className="hover:bg-red-300 bg-red-500 text-white py-2 px-6 rounded-lg">
                                            Reject
                                        </button>
                                        <button onClick={() => handleActionClick('approve')} className="hover:bg-green-300 bg-green-500 text-white py-2 px-6 rounded-lg">
                                            Approve
                                        </button>
                                    </div> :
                                    <button onClick={() => handleActionClick('paid')} className="hover:bg-green-300 bg-green-500 text-white py-2 px-6 rounded-lg">
                                        Pay
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div>
                <NavBar />
            </div>

            <div
                id="demo"
                className="flex justify-center items-center mt-8 w-full bg-white py-12"
            >

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <DetailInvoice data={invoiceData} />
                )}
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {modalAction === 'approve' ? 'Approve Invoice' : 'Reject Invoice'}
                            </h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to {modalAction} this invoice?
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    onClick={handleConfirm}
                                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                >
                                    Confirm
                                </button>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="mt-3 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
}

export default FinreqInvoice;