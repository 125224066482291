import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Notiflix from 'notiflix';

const Invoice = (props) => {
    const { inv, year, id } = useParams();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [invoices, setInvoices] = useState([])
    const [errors, setErrors] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();


    const invoiceData = location.state;

    console.log(invoiceData);

    console.log('123412');

    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleBack = () => {
        navigate('/report');
    };

    const handleChange = (e) => {
        const value = e.target.value
        const checked = e.target.checked
        errors.products = []
        if (checked) {
            setInvoices([
                ...invoices, value
            ])
        } else {
            setInvoices(invoices.filter((e) => (e !== value)))
        }

    }
    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const handleDownload = () => {
        // Implement download logic here
        console.log("Download button clicked");
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log("File uploaded:", file);
        // Implement file upload logic here
    };

    const DetailInvoice = ({ data }) => {

        const items = data.items.map(item => item);

        console.log(data);

        console.log(items);


        return (
            <div className="container mx-auto my-8 px-4" data-aos="zoom-in">
                <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                    {/* Invoice/Bill Details Section */}
                    <div className="p-6">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">Invoice/Bill Details</h2>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-md text-sm text-md font-medium leading-5 mb-2">Reference Number:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.refNo || '1231241214'}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Number:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.invoiceNo}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(invoiceData.total).toLocaleString('id-ID')} {invoiceData.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Paid Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(invoiceData.total).toLocaleString('id-ID')} {invoiceData.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Invoice Date:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.invoiceDate}</span>

                            </div>
                            <div>
                                <p className="text-md text-sm text-md font-medium leading-5 mb-2">User:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.userName || 'User Name'}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Customer:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.customerName}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Charge Amount:</p>
                                <span className="block text-sm text-md font-medium leading-5">{parseInt(invoiceData.tax).toLocaleString('id-ID')} {invoiceData.currency}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Collection Account:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.collectionAccount}</span>
                                <p className="text-md text-sm text-md font-medium leading-5 mt-4 mb-2">Due Date:</p>
                                <span className="block text-sm text-md font-medium leading-5">{invoiceData.invoiceDueDate}</span>
                            </div>
                        </div>
                    </div>

                    {/* Items Section */}
                    <div className="p-6">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-sm text-md font-medium leading-5">Item</th>
                                    <th className="px-6 py-3 text-left text-sm text-md font-medium leading-5">Item Description</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Price</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Qty</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Discount</th>
                                    <th className="px-6 py-3 text-right text-sm text-md font-medium leading-5">Amount</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-md font-medium leading-5 h-auto">{item.itemCode}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-md font-medium leading-5 h-auto">{item.itemName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.price.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.quantity}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.tax.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-md font-medium leading-5 h-auto">{item.subtotalNett.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Summary Section */}
                    <div className="p-6 bg-white flex justify-end ">
                        <div className="w-full max-w-xs">
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Amount Incl Tax</p>
                                <p>{parseInt(invoiceData.tax).toLocaleString('id-ID')} {invoiceData.currency}</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Tax Amount</p>
                                <p>{parseInt(invoiceData.tax).toLocaleString('id-ID')} {invoiceData.currency}</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Paid Amount</p>
                                <p>{parseInt(invoiceData.total).toLocaleString('id-ID')} {invoiceData.currency}</p>
                            </div>
                            <div className="flex justify-between font-semibold text-md text-md leading-5 mt-2">
                                <p>Invoice Amount</p>
                                <p>{parseInt(invoiceData.total).toLocaleString('id-ID')} {invoiceData.currency}</p>
                            </div>
                        </div>
                    </div>

                    {/* Summary Section */}
                    {/* <div className="p-6 bg-white flex justify-end ">
                        <div className="w-full max-w-xs">
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Amount Incl Tax</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Tax Amount</p>
                            </div>
                            <div className="flex justify-between text-sm text-md font-medium leading-5">
                                <p>Total Paid Amount</p>
                            </div>
                            <div className="flex justify-between font-semibold text-md text-md leading-5 mt-2">
                                <p>Invoice Amount</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex justify-between mt-4">
                        <button onClick={() => handleBack()} className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const arrayOfObjects = [
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
        { endpoint: 4, title: 5, id: 6 },
        { endpoint: 7, title: 8, id: 9 },
        { endpoint: 1, title: 2, id: 3 },
    ];

    function sendEmail(e) {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)
        fData.append('products', invoices)

        // emailjs.sendForm('service_7uy4ojg', 'template_et9wvdg', e.target, 'user_uE0bSPGbhRTmAF3I2fd3s')
        //   .then((result) => {
        //       console.log(result.text);
        //       Notiflix.Report.success(
        //         'Success',
        //         '"Thanks for sending a message, we\'ll be in touch soon."',
        //         'Okay',
        //         );
        //   }, (error) => {
        //       console.log(error.text);
        //       Notiflix.Report.failure(
        //         'An error occured',
        //         'Please try sending the message again.',
        //         'Okay',
        //         );
        //   });

        axios({
            method: "post",
            url: process.env.REACT_APP_DEMO_REQUEST_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div
                id="demo"
                className="flex justify-center items-center mt-8 w-full bg-white py-12"
            >

                <DetailInvoice data={invoiceData} />

            </div>
            <Footer />

        </>
    );

}

export default Invoice;