import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import { useParams } from "react-router-dom";

import axios from "axios";
// import emailjs from 'emailjs-com';
import Notiflix from "notiflix";

import upload_btn from "../images/upload.png";
import download_btn from "../images/download.png";
import back_btn from "../images/back.png";
import { useNavigate } from 'react-router-dom';



const invoiceLocalUrl = 'http://localhost:3000'



const ListInvoice = (props) => {
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [demoProducts, setDemoProducts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [verifyFile, setVerifyFile] = useState(null);
  const navigate = useNavigate();


  const handleUpload = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    navigate('/report');
  };


  useEffect(() => {
    fetchInvoiceDetail();
  }, []);

  // const handleChange = (e) => {
  //     const value = e.target.value
  //     const checked = e.target.checked
  //     errors.products = []
  //     if (checked) {
  //         setDemoProducts([
  //             ...demoProducts, value
  //         ])
  //     } else {
  //         setDemoProducts(demoProducts.filter((e) => (e !== value)))
  //     }

  // }
  // const clearErrors = () => {
  //     setErrors([])
  // }

  // const clearInput = () => {
  //     setFirstName('')
  //     setLastName('')
  //     setEmail('')
  //     setPhone('')
  //     setMessage('')
  // }

  const fetchInvoiceDetail = async () => {
    console.log("123"); // Check if the function is being called

    console.log(id);

    try {
      const response = await axios.get(
        `https://beams-fe-dev.bayargw.com/beamsinvoice/invoice-file/detail/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Add any other headers if required
          },
        }
      );

      console.log("Full response:", response); // Log the entire response
      console.log("Data:", response.data.data); // Log the data specifically
      setInvoiceData(response.data.data);
    } catch (error) {
      console.log("Error fetching invoice details:", error); // Log any error that occurs
    }
  };

  const postFileExcel = async () => {
    console.log("123"); // Check if the function is being called

    if (!file) {
      setError("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Assuming the backend expects a 'file' key

    try {
      const response = await axios.post(
        "https://beams-fe-dev.bayargw.combeamsinvoice/invoice-file/verify",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers if required
          },
        }
      );

      console.log("Full response:", response); // Log the entire response
      console.log("Data:", response.data); // Log the data specifically

      if (response.data) {
        setVerifyFile(response);
      } else {
        setError("No data available.");
      }
    } catch (err) {
      console.log("Error fetching invoice details:", error);
    }
  };

  const handleDownload = () => {
    // Implement download logic here
    console.log("Download button clicked");
    fetchInvoiceDetail();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log("File uploaded:", selectedFile);
  };

  const dataList = [
    {
      rowNum: 2,
      warungName: 7,

      invoiceDate: "2024-08-08",
      isPartnerActive: true,
      partnerName: "Rizky Mart",
      partnerEmail: "088708870887@bakuul.com",
      partnerAddress:
        "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-08",
      invoiceNo: "INV/2024/00034",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 305,
      taxAmount: null,
      totalCurrency: 305,
      total: 305,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060381",
    },
    {
      rowNum: 3,
      warungName: 2,

      invoiceDate: "2024-08-06",
      isPartnerActive: true,
      partnerName: "Rizky Mart",
      partnerEmail: "088708870887@bakuul.com",
      partnerAddress:
        "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-06",
      invoiceNo: "INV/2024/00033",
      paymentStatus: "Waiting_approval",
      untaxedAmount: 90,
      taxAmount: null,
      totalCurrency: 90,
      total: 90,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060382",


    },
    {
      rowNum: 4,
      warungName: 2,

      invoiceDate: "2024-08-06",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-06",
      invoiceNo: "INV/2024/00032",
      paymentStatus: "PAID",
      untaxedAmount: 18,
      taxAmount: null,
      totalCurrency: 18,
      total: 18,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060383",


    },
    {
      rowNum: 5,
      warungName: 5,

      invoiceDate: "2024-08-06",
      isPartnerActive: true,
      partnerName: "Rizky Mart",
      partnerEmail: "088708870887@bakuul.com",
      partnerAddress:
        "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-06",
      invoiceNo: "INV/2024/00031",
      paymentStatus: "PAID",
      untaxedAmount: 280,
      taxAmount: null,
      totalCurrency: 280,
      total: 280,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060384",

    },
    {
      rowNum: 6,
      warungName: 4,

      invoiceDate: "2024-08-06",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-06",
      invoiceNo: "INV/2024/00030",
      paymentStatus: "REJECT",
      untaxedAmount: 100520,
      taxAmount: null,
      totalCurrency: 100520,
      total: 100520,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060385",
    },
    {
      rowNum: 7,
      warungName: 7,

      invoiceDate: "2024-08-05",
      isPartnerActive: true,
      partnerName: "Rizky Mart",
      partnerEmail: "088708870887@bakuul.com",
      partnerAddress:
        "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-08-05",
      invoiceNo: "INV/2024/00029",
      paymentStatus: "PAID",
      untaxedAmount: 16,
      taxAmount: null,
      totalCurrency: 16,
      total: 16,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060386",

    },
    {
      rowNum: 8,
      warungName: 6,

      invoiceDate: "2024-07-03",
      isPartnerActive: true,
      partnerName: "Rizky Mart",
      partnerEmail: "088708870887@bakuul.com",
      partnerAddress:
        "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-07-03",
      invoiceNo: "INV/2024/00028",
      paymentStatus: "Waiting_approval",
      untaxedAmount: 38,
      taxAmount: null,
      totalCurrency: 38,
      total: 38,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060387",

    },
    {
      rowNum: 9,
      warungName: 7,

      invoiceDate: "2024-04-25",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-25",
      invoiceNo: "INV/2024/00027",
      paymentStatus: "Waiting_approval",
      untaxedAmount: 955,
      taxAmount: null,
      totalCurrency: 955,
      total: 955,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060387",

    },
    {
      rowNum: 10,
      warungName: 7,

      invoiceDate: "2024-04-24",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-24",
      invoiceNo: "INV/2024/00026",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 955,
      taxAmount: null,
      totalCurrency: 955,
      total: 955,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060388",

    },
    {
      rowNum: 11,
      warungName: 7,

      invoiceDate: "2024-04-24",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-24",
      invoiceNo: "INV/2024/00025",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 1295,
      taxAmount: null,
      totalCurrency: 1295,
      total: 1295,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060389",

    },
    {
      rowNum: 12,
      warungName: 3,

      invoiceDate: "2024-04-24",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-24",
      invoiceNo: "INV/2024/00024",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 137,
      taxAmount: null,
      totalCurrency: 137,
      total: 137,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060390",

    },
    {
      rowNum: 13,
      warungName: 4,

      invoiceDate: "2024-04-24",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-24",
      invoiceNo: "INV/2024/00023",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 1295,
      taxAmount: null,
      totalCurrency: 1295,
      total: 1295,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060391",

    },
    {
      rowNum: 14,
      warungName: 5,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00022",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 12875,
      taxAmount: null,
      totalCurrency: 12875,
      total: 12875,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060391",

    },
    {
      rowNum: 15,
      warungName: 6,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00021",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 7005,
      taxAmount: null,
      totalCurrency: 7005,
      total: 7005,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060392",

    },
    {
      rowNum: 16,
      warungName: 2,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00020",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 665,
      taxAmount: null,
      totalCurrency: 665,
      total: 665,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060393",

    },
    {
      rowNum: 17,
      warungName: 4,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00019",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 678,
      taxAmount: null,
      totalCurrency: 678,
      total: 678,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060394",

    },
    {
      rowNum: 18,
      warungName: 2,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00018",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 678,
      taxAmount: null,
      totalCurrency: 678,
      total: 678,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060395",

    },
    {
      rowNum: 19,
      warungName: 4,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00017",
      paymentStatus: "PAID",
      untaxedAmount: 2865,
      taxAmount: null,
      totalCurrency: 2865,
      total: 2865,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060396",

    },
    {
      rowNum: 20,
      warungName: 3,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "TestingMTVEcho",
      partnerEmail: "097776764955@bakuul.com",
      partnerAddress:
        "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00016",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 165,
      taxAmount: null,
      totalCurrency: 165,
      total: 165,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060397",

    },
    {
      rowNum: 21,
      warungName: 2,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00015",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 276,
      taxAmount: null,
      totalCurrency: 276,
      total: 276,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060398",

    },
    {
      rowNum: 22,
      warungName: 5,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00014",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 678,
      taxAmount: null,
      totalCurrency: 678,
      total: 678,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060399",

    },
    {
      rowNum: 23,
      warungName: 3,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00013",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 1345,
      taxAmount: null,
      totalCurrency: 1345,
      total: 1345,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060400",

    },
    {
      rowNum: 24,
      warungName: 4,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "TestingMTVEcho",
      partnerEmail: "097776764955@bakuul.com",
      partnerAddress:
        "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00012",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 165,
      taxAmount: null,
      totalCurrency: 165,
      total: 165,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060401",

    },
    {
      rowNum: 25,
      warungName: 7,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "TestingMTVEcho",
      partnerEmail: "097776764955@bakuul.com",
      partnerAddress:
        "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00011",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 15,
      taxAmount: null,
      totalCurrency: 15,
      total: 15,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060401",

    },
    {
      rowNum: 26,
      warungName: 6,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00010",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 665,
      taxAmount: null,
      totalCurrency: 665,
      total: 665,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060402",

    },
    {
      rowNum: 27,
      warungName: 5,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "jihan",
      partnerEmail: "08886845640@bakuul.com",
      partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00009",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 665,
      taxAmount: null,
      totalCurrency: 665,
      total: 665,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060403",

    },
    {
      rowNum: 28,
      warungName: 6,

      invoiceDate: "2024-04-23",
      isPartnerActive: true,
      partnerName: "Ecomart",

      partnerEmail: "087908790879@bakuul.com",
      partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
      dueDate: "2024-04-23",
      invoiceNo: "INV/2024/00008",
      paymentStatus: "Waiting_payment",
      untaxedAmount: 665,
      taxAmount: null,
      totalCurrency: 665,
      total: 665,
      currency: "PHP",
      reference_number: "INV-081224-225434-0060404",

    },
  ];


  function sendEmail(e) {
    e.preventDefault();
  }

  const replaceSlashesWithHyphens = (id) => {
    return id.replace(/\//g, '-');
  };

  const handleNavigateInvoice = (item, id) => {


    console.log(id);
    
    const refNo = replaceSlashesWithHyphens(id) || '412312412341321';
    navigate(`/detail-invoices/${refNo}`, { state: item });
  };


  const handleNavigateCustProfile = (id) => {
    console.log(id);
    const refNo = replaceSlashesWithHyphens(id) || '412312412341321';
    navigate(`/cust-profile/${id || 1231412123}`, { state: id });
};

  const InvoiceTable = ({ data }) => {
    console.log(data);

    return (
      <div className="w-full overflow-x-auto">
        <div className="overflow-y-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reference No
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Outstanding Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Paid Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Due Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((invoice) => {
                return (
                  <tr key={invoice.invoiceNo}>
                    <td className="px-6 py-4 whitespace-normal text-sm text-gray-900 break-words">
                      <a
                        onClick={() => handleNavigateInvoice(invoice, invoice.invoiceNo)}
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700 underline"
                      >
                        {invoice.refNo || '412312412341321'}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 break-words">
                      {invoice.userName || 'User Name'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {invoice.invoiceNo}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <a
                        onClick={() => handleNavigateCustProfile(invoice.partnerName || 'Partner')}
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700 underline"
                      >
                        <span>{invoice.partnerName || 'Parner Name'}</span>
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {parseInt(invoice.total).toLocaleString('id-ID')} {invoice.currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {invoice.paymentStatus === 'PAID' ? `0 ${invoice.currency}` : `${parseInt(invoice.totalNett).toLocaleString('id-ID')} ${invoice.currency}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {invoice.paymentStatus !== 'PAID' ? `0 ${invoice.currency}` : parseInt(invoice.totalNett).toLocaleString('id-ID')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {invoice.invoiceDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {invoice.invoiceDueDate}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const UploadModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      //     <div className="bg-white rounded-lg p-6 w-11/12 max-w-md">

      //     </div>
      // </div>

      <div
        className="fixed inset-0 flex items-center justify-center z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        <div className="bg-green-200 w-full max-w-6xl p-8 rounded-lg shadow-lg z-10">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
          {/* Modal content */}
          <div className="container mx-auto mt-10">
            <TransactionList />
          </div>

          <div
            type="input"
            className="border-2 border-dashed mt-5 border-gray-300 rounded-lg p-8 text-center"
          >
            <div className="mb-4">
              <svg
                className="mx-auto h-12 w-12 text-gray-400" /* SVG path here */
              />
            </div>
            <p className="text-lg mb-2">Drag and drop your file here</p>
            <input
              type="file"
              className="text-sm text-stone-500
                        file:mr-5 file:py-1 file:px-3 file:border-[1px]
                        file:text-xs file:font-medium
                        file:bg-stone-50 file:text-stone-700
                        hover:file:cursor-pointer hover:file:bg-blue-50
                        hover:file:text-blue-700"
              onChange={handleFileChange}
            />
            <p className="text-xs text-gray-400 mt-2">(Max file size: 2 MB)</p>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={postFileExcel}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };


  const handleBackToBo = () => {
    window.location.href = 'localhost:3000/bo-dash/dashboard'
  }

  const TransactionList = () => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">List Transaction</h2>

        <div className="overflow-x-auto">
          <table className="w-full text-sm">
            <thead>
              <tr className="text-left text-gray-600">
                <th className="pb-3">Invoice Number</th>
                <th className="pb-3">Customer</th>
                <th className="pb-3">Invoice Amount</th>
                <th className="pb-3">Paid Amount</th>
                <th className="pb-3">Collection Account</th>
                <th className="pb-3">Invoice Date</th>
                <th className="pb-3">Due Date</th>
                <th className="pb-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-red-100">
                <td className="py-2"></td>
                <td className="py-2 flex items-center">
                  <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>
                  BUDI
                </td>
                <td className="py-2">0.00</td>
                <td className="py-2">0.00</td>
                <td className="py-2">0.00</td>
                <td className="py-2">0.00</td>
                <td className="py-2">0.00</td>
                <td className="py-2 text-red-600 flex items-center">
                  <span className="w-2 h-2 bg-red-600 rounded-full mr-1"></span>
                  Invalid
                </td>
              </tr>
              <tr>
                <td className="py-2">1234567890</td>
                <td className="py-2 flex items-center">
                  <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>
                  DOREMI,BUDI
                </td>
                <td className="py-2">1,000,000.00</td>
                <td className="py-2">0.00</td>
                <td className="py-2">000000000203</td>
                <td className="py-2">10/08/2024 11:45 AM</td>
                <td className="py-2">08/17/2024</td>
                <td className="py-2 text-green-600 flex items-center">
                  <span className="w-2 h-2 bg-green-600 rounded-full mr-1"></span>
                  Complete
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          <span className="font-semibold">Total Amount</span>
          <span className="ml-2">IDR</span>
          <span className="ml-2">1,000.00</span>
        </div>

        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mt-6 text-sm text-blue-800">
          <p>
            X complete and correctly formatted data entries will be saved and
            displayed in the transaction report after pressing the Save button.
          </p>
          <p>
            X incomplete data entries, please update the data and re-upload the
            file after pressing the Save button.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="demo"
        className="flex justify-center items-center mt-8 w-full bg-green-50 py-12"
      >

        <div className="container mx-auto my-8 px-4" data-aos="zoom-in">
          <form onSubmit={sendEmail} id="demoProductForm">
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <h1 className="my-5 mb-5 font-bold text-center lg:text-left text-green-900 uppercase text-4xl">
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700 focus:outline-none mx-3"
                  onClick={handleBackToBo}
                  style={{ background: "none", border: "none", padding: 0 }}
                >
                  <img
                    src={back_btn}
                    alt="Download"
                    style={{ width: "22px", height: "22px" }}
                  />
                </button>
                SME TRANSACTION REPORT
              </h1>
              {<InvoiceTable data={invoiceData} />}
            </div>
            <div class="flex justify-between mt-4">
              <button onClick={() => handleBack()} class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Back
              </button>
            </div>
          </form>
        </div>
        <UploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>

      <Footer />
    </>
  );
};

export default ListInvoice;
