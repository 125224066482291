import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import { useParams } from "react-router-dom";
import axios from "axios";
import Notiflix from "notiflix";
import filter_btn from "../images/filter.png";
import download_btn from "../images/download.png";
import { useNavigate } from 'react-router-dom';


const invoiceLocalUrl = 'http://localhost:3000'

console.log(invoiceLocalUrl);

const FinacialRequest = (props) => {
    const { id } = useParams();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [finacialRequests, setFinacialRequests] = useState([]);
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [verifyFile, setVerifyFile] = useState(null);
    const navigate = useNavigate();



    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchInvoiceDetail();
    }, []);

    const fetchInvoiceDetail = async () => {
        console.log("123"); // Check if the function is being called

        console.log(id);

        try {
            const response = await axios.get(
                `https://beams-fe-dev.bayargw.com/beamsinvoice/financial-report/list`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        // Add any other headers if required
                    },
                }
            );

            console.log("Full response:", response); // Log the entire response
            console.log("Data:", response.data.data); // Log the data specifically
            setInvoiceData(response.data.data);
            setFilteredData(response.data.data); // Initialize filteredData with the full data
        } catch (error) {
            console.log("Error fetching invoice details:", error); // Log any error that occurs
        }
    };

    const handleDownload = () => {
        // Implement download logic here
        console.log("Download button clicked");
        fetchInvoiceDetail();
    };

    function sendEmail(e) {
        e.preventDefault();
    }

    const dataList = [
        {
            rowNum: 2,
            warungName: 7,

            invoiceDate: "2024-08-08",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-08",
            invoiceNo: "INV/2024/00034",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 305,
            taxAmount: null,
            totalCurrency: 305,
            total: 305,
            currency: "PHP",
            refNo: "INV-081224-225434-0060381",
        },
        {
            rowNum: 3,
            warungName: 2,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00033",
            paymentStatus: "Waiting_approval",
            untaxedAmount: 90,
            taxAmount: null,
            totalCurrency: 90,
            total: 90,
            currency: "PHP",
            refNo: "INV-081224-225434-0060382",


        },
        {
            rowNum: 4,
            warungName: 2,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00032",
            paymentStatus: "PAID",
            untaxedAmount: 18,
            taxAmount: null,
            totalCurrency: 18,
            total: 18,
            currency: "PHP",
            refNo: "INV-081224-225434-0060383",


        },
        {
            rowNum: 5,
            warungName: 5,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00031",
            paymentStatus: "PAID",
            untaxedAmount: 280,
            taxAmount: null,
            totalCurrency: 280,
            total: 280,
            currency: "PHP",
            refNo: "INV-081224-225434-0060384",

        },
        {
            rowNum: 6,
            warungName: 4,

            invoiceDate: "2024-08-06",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-06",
            invoiceNo: "INV/2024/00030",
            paymentStatus: "REJECT",
            untaxedAmount: 100520,
            taxAmount: null,
            totalCurrency: 100520,
            total: 100520,
            currency: "PHP",
            refNo: "INV-081224-225434-0060385",
        },
        {
            rowNum: 7,
            warungName: 7,

            invoiceDate: "2024-08-05",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-08-05",
            invoiceNo: "INV/2024/00029",
            paymentStatus: "PAID",
            untaxedAmount: 16,
            taxAmount: null,
            totalCurrency: 16,
            total: 16,
            currency: "PHP",
            refNo: "INV-081224-225434-0060386",

        },
        {
            rowNum: 8,
            warungName: 6,

            invoiceDate: "2024-07-03",
            isPartnerActive: true,
            partnerName: "Rizky Mart",
            partnerEmail: "088708870887@bakuul.com",
            partnerAddress:
                "St alpha putra\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-07-03",
            invoiceNo: "INV/2024/00028",
            paymentStatus: "Waiting_approval",
            untaxedAmount: 38,
            taxAmount: null,
            totalCurrency: 38,
            total: 38,
            currency: "PHP",
            refNo: "INV-081224-225434-0060387",

        },
        {
            rowNum: 9,
            warungName: 7,

            invoiceDate: "2024-04-25",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-25",
            invoiceNo: "INV/2024/00027",
            paymentStatus: "Waiting_approval",
            untaxedAmount: 955,
            taxAmount: null,
            totalCurrency: 955,
            total: 955,
            currency: "PHP",
            refNo: "INV-081224-225434-0060387",

        },
        {
            rowNum: 10,
            warungName: 7,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00026",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 955,
            taxAmount: null,
            totalCurrency: 955,
            total: 955,
            currency: "PHP",
            refNo: "INV-081224-225434-0060388",

        },
        {
            rowNum: 11,
            warungName: 7,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00025",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 1295,
            taxAmount: null,
            totalCurrency: 1295,
            total: 1295,
            currency: "PHP",
            refNo: "INV-081224-225434-0060389",

        },
        {
            rowNum: 12,
            warungName: 3,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00024",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 137,
            taxAmount: null,
            totalCurrency: 137,
            total: 137,
            currency: "PHP",
            refNo: "INV-081224-225434-0060390",

        },
        {
            rowNum: 13,
            warungName: 4,

            invoiceDate: "2024-04-24",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-24",
            invoiceNo: "INV/2024/00023",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 1295,
            taxAmount: null,
            totalCurrency: 1295,
            total: 1295,
            currency: "PHP",
            refNo: "INV-081224-225434-0060391",

        },
        {
            rowNum: 14,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00022",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 12875,
            taxAmount: null,
            totalCurrency: 12875,
            total: 12875,
            currency: "PHP",
            refNo: "INV-081224-225434-0060391",

        },
        {
            rowNum: 15,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00021",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 7005,
            taxAmount: null,
            totalCurrency: 7005,
            total: 7005,
            currency: "PHP",
            refNo: "INV-081224-225434-0060392",

        },
        {
            rowNum: 16,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00020",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            refNo: "INV-081224-225434-0060393",

        },
        {
            rowNum: 17,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00019",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            refNo: "INV-081224-225434-0060394",

        },
        {
            rowNum: 18,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00018",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            refNo: "INV-081224-225434-0060395",

        },
        {
            rowNum: 19,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00017",
            paymentStatus: "PAID",
            untaxedAmount: 2865,
            taxAmount: null,
            totalCurrency: 2865,
            total: 2865,
            currency: "PHP",
            refNo: "INV-081224-225434-0060396",

        },
        {
            rowNum: 20,
            warungName: 3,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00016",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 165,
            taxAmount: null,
            totalCurrency: 165,
            total: 165,
            currency: "PHP",
            refNo: "INV-081224-225434-0060397",

        },
        {
            rowNum: 21,
            warungName: 2,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00015",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 276,
            taxAmount: null,
            totalCurrency: 276,
            total: 276,
            currency: "PHP",
            refNo: "INV-081224-225434-0060398",

        },
        {
            rowNum: 22,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00014",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 678,
            taxAmount: null,
            totalCurrency: 678,
            total: 678,
            currency: "PHP",
            refNo: "INV-081224-225434-0060399",

        },
        {
            rowNum: 23,
            warungName: 3,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00013",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 1345,
            taxAmount: null,
            totalCurrency: 1345,
            total: 1345,
            currency: "PHP",
            refNo: "INV-081224-225434-0060400",

        },
        {
            rowNum: 24,
            warungName: 4,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00012",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 165,
            taxAmount: null,
            totalCurrency: 165,
            total: 165,
            currency: "PHP",
            refNo: "INV-081224-225434-0060401",

        },
        {
            rowNum: 25,
            warungName: 7,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "TestingMTVEcho",
            partnerEmail: "097776764955@bakuul.com",
            partnerAddress:
                "Jl Maju bersama kita kuat bercerai kita runtuh\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00011",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 15,
            taxAmount: null,
            totalCurrency: 15,
            total: 15,
            currency: "PHP",
            refNo: "INV-081224-225434-0060401",

        },
        {
            rowNum: 26,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00010",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            refNo: "INV-081224-225434-0060402",

        },
        {
            rowNum: 27,
            warungName: 5,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "jihan",
            partnerEmail: "08886845640@bakuul.com",
            partnerAddress: "ok\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00009",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            refNo: "INV-081224-225434-0060403",

        },
        {
            rowNum: 28,
            warungName: 6,

            invoiceDate: "2024-04-23",
            isPartnerActive: true,
            partnerName: "Ecomart",

            partnerEmail: "087908790879@bakuul.com",
            partnerAddress: "st kencana\n00/00 Bangued\nBangued NL 2800\nIndonesia",
            dueDate: "2024-04-23",
            invoiceNo: "INV/2024/00008",
            paymentStatus: "Waiting_payment",
            untaxedAmount: 665,
            taxAmount: null,
            totalCurrency: 665,
            total: 665,
            currency: "PHP",
            refNo: "INV-081224-225434-0060404",

        },
    ];

    const handleNavigateInvoice = (id) => {
        console.log(id);

        navigate(`/finreq-invoices/${id}`, { state: id });
    };

    const handleNavigateCustProfile = (id) => {
        console.log(id);

        navigate(`/cust-profile/${id}`, { state: id });
    };

    const InvoiceTable = ({ data }) => {
        console.log(data);

        return (
            <div className="w-full overflow-x-auto">
                <table className="min-w-full bg-white table-auto">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Reference No
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                User Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Invoice No
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Customer Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Invoice Amount
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Outstanding Amount
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Invoice Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Due Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Payment Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((invoice) => {

                            return (
                                <tr key={invoice.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 break-words">
                                        <a
                                            onClick={() => handleNavigateInvoice(invoice.id)}
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:text-blue-700 underline"
                                        >
                                            {invoice.refNo || '412312412341321'}
                                        </a>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 break-words">
                                        {invoice.userName}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.invoiceNo}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <a
                                            onClick={() => handleNavigateCustProfile(invoice.customerName)}
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:text-blue-700 underline"
                                        >
                                            {invoice.customerName || '412312412341321'}
                                        </a>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {parseInt(invoice.invoiceAmount).toLocaleString('id-ID')} {invoice.currency}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {parseInt(invoice.outStandingAmount).toLocaleString('id-ID')} {invoice.currency}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.invoiceDateFmt}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {invoice.dueDateFmt}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        <span
                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                                                ${invoice.statusRequest === "PAID" ? "bg-green-100 text-green-800" :
                                                    invoice.statusRequest === "Waiting Approval" ? "bg-blue-100 text-blue-800" :
                                                        invoice.statusRequest === "Waiting Payment" ? "bg-orange-100 text-orange-800"
                                                            : "bg-red-100 text-red-800"
                                                }`}
                                        >
                                            {invoice.statusRequest}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const FilterModal = ({ isOpen, onClose, onApply }) => {
        const [selectedUserNames, setSelectedUserNames] = useState([]);

        if (!isOpen) return null;

        const handleCheckboxChange = (userName) => {
            setSelectedUserNames((prevSelectedUserNames) =>
                prevSelectedUserNames.includes(userName)
                    ? prevSelectedUserNames.filter((name) => name !== userName)
                    : [...prevSelectedUserNames, userName]
            );
        };

        const handleApply = () => {
            const filtered = invoiceData.filter((invoice) =>
                selectedUserNames.includes(invoice.userName)
            );
            setFilteredData(filtered);
            console.log(filtered);

            onClose();
        };

        // Get unique warung names
        const uniqueUserNames = [...new Set(invoiceData.map(invoice => invoice.userName))];

        return (
            <div
                className="fixed inset-0 flex items-center justify-center z-50 overflow-x-auto overflow-y-auto"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                <div className="bg-white w-4/12 max-w-6xl p-8 rounded-lg shadow-lg z-10">
                    <div>
                        <h3 className="flex justify-between items-center text-lg font-semibold mb-4">Filters</h3>
                        <div>
                            <table className="min-w-full bg-white">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Select
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Warung Name
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {uniqueUserNames.map((userName) => (
                                        <tr key={userName}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUserNames.includes(userName)}
                                                    onChange={() => handleCheckboxChange(userName)}
                                                />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{userName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-800 hover:text-gray-800 mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleApply}
                            className="px-4 py-2 bg-green-500 mx-2 text-white rounded hover:bg-green-600"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div
                id="demo"
                className="flex justify-center items-center mt-8 w-full bg-green-50 py-12"
            >
                <div className="container mx-auto my-8 px-4 py-4" data-aos="zoom-in">
                    <form onSubmit={sendEmail} id="finacialRequestForm">
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                                SME FINANCIAL REQUEST REPORT
                            </h1>
                            <div className="flex justify-end items-center mt-6 mb-4">
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                                        placeholder="Search anything..."
                                    />
                                    <div className="absolute top-4 right-3">
                                        <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
                                    </div>
                                </div>

                                {/* Upload button */}
                                <button
                                    onClick={() => setIsModalOpen(true)}
                                    style={{ background: "none", border: "none", padding: 0 }}
                                >
                                    <img
                                        src={filter_btn}
                                        alt="Upload"
                                        style={{ width: "22px", height: "22px" }}
                                    />
                                </button>
                            </div>
                            <div className="my-8">{<InvoiceTable data={filteredData.length > 0 ? filteredData : invoiceData} />}</div>
                        </div>
                    </form>
                </div>
                <FilterModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            </div>
            <Footer />
        </>
    );
};

export default FinacialRequest;